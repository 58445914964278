<template >
  <div>
    <b-card-code :title="name">

      <!-- form -->
      <validation-observer ref="CVDEditForm" #default="{ invalid }">
        <b-form class="auth-login-form mt-2" @submit.prevent="editCVD">
          <!-- Name -->
          <b-form-group label="Origin Name" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="VM Name" label-for="vm_name">
            <validation-provider
              #default="{ errors }"
              name="vm_name"
              rules="required"
            >
              <b-form-input
                id="vm_name"
                v-model="vm_name"
                :state="errors.length > 0 ? false : null"
                name="vm_name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        <b-form-group label="" label-for="Is Publsihed?">
          <b-form-checkbox
            id="checkbox-2"
            v-model="is_reviewed"
            name="is_reviewed"
            value="true"
            unchecked-value="false"
          >
            Is Published?
          </b-form-checkbox>
        </b-form-group>
          <b-form-group label="CVSS Score" label-for="cvss_score">
            <validation-provider
              #default="{ errors }"
              name="cvss_score"
              rules="required"
            >
              <b-form-input
                id="cvss_score"
                v-model="cvss_score"
                :state="errors.length > 0 ? false : null"
                name="cvss_score"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        <b-form-group label="OWASP Category" label-for="owasp_category">
            <v-select
              v-model="owasp_categories"
              multiple
              label="text"
              :options="owasp_options"
              @search="fetchOwaspOptions"
              autocomplete
            />
        </b-form-group>

        <b-form-group label="CVE" label-for="CVE">
          <b-form-textarea
            v-model="cve"
          ></b-form-textarea>
        </b-form-group>

          <b-form-group label="Severity" label-for="Severity">
            <validation-provider
              #default="{ errors }"
              name="severity"
              rules="required"
            >
              <b-form-select
                v-model="severity"
                :options="severity_options"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description" label-for="description">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules="required"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                :state="errors.length > 0 ? false : null"
                name="description"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Synopsis" label-for="synopsis">
            <validation-provider
              #default="{ errors }"
              name="synopsis"
              rules="required"
            >
              <b-form-textarea
                id="synopsis"
                v-model="synopsis"
                :state="errors.length > 0 ? false : null"
                name="synopsis"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Recommendation" label-for="recommendation">
            <validation-provider
              #default="{ errors }"
              name="recommendation"
              rules="required"
            >
              <b-form-textarea
                id="recommendation"
                v-model="recommendation"
                :state="errors.length > 0 ? false : null"
                name="recommendation"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="References" label-for="references">
            <b-form-textarea
              id="references"
              v-model="references"
              name="references"
            />
          </b-form-group>
          <b-button type="submit" variant="primary" :disabled="invalid">
            Update
          </b-button>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>
<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    vSelect
  },
  data: function () {
    return {
      name: "",
      vm_name: "",
      synopsis: "",
      description: "",
      cvss_score: "",
      owasp_categories: [],
      recommendation: "----",
      references: "----",
      severity: "",
      cve: "",
      is_reviewed: false,
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      owasp_options: [],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const o_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "master/vulnerability/" +
          this.$route.params.id, //+'/vuln-details/',
      };
      var self = this;
      this.$http(o_options).then((res) => {
        let data = res.data;
        self.name = data.name;
        self.vm_name = data.vm_name;
        self.description = data.description;
        self.synopsis = data.synopsis;
        self.recommendation = data.recommendation;
        self.cvss_score = data.cvss_score;
        self.owasp_categories= data.owasp_categories;
        self.severity = data.severity;
        self.cve = data.cve;
        self.is_reviewed = data.is_reviewed;
      });
    },
    editCVD: function () {
      this.$refs.CVDEditForm.validate().then((success) => {
        if (success) {
          let data = {
            name: this.name,
            vm_name: this.vm_name,
            description: this.description,
            synopsis: this.synopsis,
            recommendation: this.recommendation,
            cvss_score: this.cvss_score,
            owasp_categories: this.owasp_categories,
            severity: this.severity,
            cve: this.cve,
            is_reviewed: this.is_reviewed,
          };
          const options = {
            method: "PATCH",
            headers: { "content-type": "application/json" },
            data: data,
            url:
              process.env.VUE_APP_BASEURL +
              "master/vulnerability/" +
              this.$route.params.id +
              "/",
          };
          this.$http(options).then((res) => {
            console.log(res);
            if (res.data.id) {
              this.$router.push({ name: "eca" });
            }
          });
        }
      });
    },
    fetchOwaspOptions(search, loading){
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "master/owasp?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.owasp_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: value.title,
              text: value.title,
              // full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.owasp_options.push(a);

          });
        });
      }
    }
  },
};
</script>
<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>